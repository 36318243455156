.linechart_axis {
  stroke: #707070;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.labels {
  font-size: 0.5rem;
  fill: #616161;
  color: #616161;
}

.labels.x-labels {
  text-anchor: middle;
  font-weight: 500;
}

.labels.x-numbers {
  text-anchor: middle;
  fill: #707070;
}

.data {
  fill: #fff;
  font-size: 0.6rem;
}

.data:hover {
  cursor: pointer;
}

.shield {
  fill: #0677A1;
  color: #0677A1;
}

.comment {
  fill: #fff;
  color: #fff;
  stroke: #333;
  stroke-width: 80;
}

.confidence-text {
  fill: #fff;
  font-size: 0.475rem;
  font-weight: 700;
}

@media print {
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  .no-print, .no-print * {
    display: none !important;
  }
}